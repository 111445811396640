import React from 'react'
import styled from "styled-components";

import mockup1 from "../assets/img/mockup1.png";
import apple from "../assets/img/apple.png"
import playstore from "../assets/img/playstore.png"

import ship from "../assets/img/ship.jpg"
import notice from "../assets/img/notice.jpg"
import foreshore from "../assets/img/foreshore.jpg"

import mockup2 from "../assets/img/mockup2.png";
import mockup3 from "../assets/img/mockup3.png";
import mockup4 from "../assets/img/mockup4.png";
import mockup5 from "../assets/img/mockup5.png";

import fishacc from "../assets/img/fishacc.png";
import fishacc2 from "../assets/img/fishacc2.png";
import seaacc from "../assets/img/seaacc.png";
import seaacc2 from "../assets/img/seaacc2.png";
import shipacc from "../assets/img/shipacc.png";
import shipacc2 from "../assets/img/shipacc2.png";
import swimacc from "../assets/img/swimacc.png";
import swimacc2 from "../assets/img/swimacc2.png";

import AOS from 'aos';
import 'aos/dist/aos.css';

const DownloadBtn = styled.button`
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  width: 18rem;
  background-color: #4A69EA;
  border-radius: 1rem;
  line-height: 5rem;
`;

function Product() {
  return (
    <>
      {/* <section className="xl:pt-20 xl:pb-20 lg:pt-10 lg:pb-10 md:pt-10 md:pb-10 bg-gradient-to-b from-gradient1 from-30% to-gradient2 xl:mt-64 lg:mt-64 md:mt-48 sm:mt-32 mt-32"> */}
      <section className="mt-32 xl:pt-20 xl:pb-20 lg:pt-10 lg:pb-10 md:pt-10 md:pb-10 xl:mt-64 lg:mt-64 md:mt-48 sm:mt-32">
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center py-10 xl:py-0 lg:py-0 md:py-0 sm:py-0">
          <div className="flex w-8/12 px-12 ml-auto mr-auto md:w-6/12 lg:w-4/12 xl:mb-0 lg:mb-0 md:mb-0 sm:mt-16 animate-fade ">
              <div className="relative flex flex-col w-full min-w-0 overflow-hidden break-words animate-wiggle">
                <img
                  alt="..."
                  src={mockup1}
                />
              </div>
            </div>
            <div className="w-full px-4 mt-5 ml-auto mr-auto md:w-6/12 xl:mt-0 xl:mb-0 lg:mt-10 lg:mb-10 md:mt-10 md:mb-10 sm:mt-16 sm:mb-24">
              <div className="relative flex flex-col overflow-hidden animate-fade-right">
                <h2 className="flex w-full mb-2 text-2xl font-semibold 2xl:mb-10 xl:mb-6 lg:mb-4 md:mb-2 sm:mb-2 2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-3xl">
                  안전한 교통기본권 지킴이,&nbsp;<div className="text-blue-800">천사뱃길</div>
                </h2>
                <p className='mb-8 text-sm text-gray-600 2xl:leading-10 xl:leading-8 lg:leading-7 md:leading-5 sm:leading-6 2xl:text-2xl xl:text-xl lg:text-xl md:text-md 2xl:mb-20 xl:mb-14 lg:mb-10 md:mb-8 sm:mb-10'>
                  천사뱃길은 신안 여객선의 도착정보, 갯벌의 통행 신호 체계,<br/>
                  신안 및 섬 지역의 안전 정보 등을 제공하는 플랫폼입니다.
                </p>
                <div className='mt-2 md:flex 2xl:gap-4 xl:gap-4 lg:gap-3 md:gap-2'>
                  <button className='flex justify-center w-full py-2 text-base font-medium text-white align-middle opacity-100 md:w-6/12 hover:opacity-70 bg-blue-950 2xl:text-2xl xl:text-xl lg:text-lg md:text-base 2xl:py-5 xl:py-5 lg:py-3 md:py-2 sm:py-4 hover:bg-blue-950 rounded-xl'>
                    <img src={apple} className='h-5 mr-2 lg:h-8'/>Download App
                  </button>

                  <button className='flex justify-center w-full py-2 mt-3 text-base font-medium text-white align-middle opacity-100 md:w-6/12 md:mt-0 sm:mt-3 hover:opacity-70 bg-blue-950 2xl:text-2xl xl:text-xl lg:text-lg md:text-base 2xl:py-5 xl:py-5 lg:py-3 md:py-2 sm:py-4 hover:bg-blue-950 rounded-xl'>
                    <img src={playstore} className='h-5 mr-3 lg:h-6'/>Download App
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-10 2xl:py-32 lg:py-24 md:py-20 sm:py-24 bg-product2">
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center">

            <div className="w-full px-4 ml-auto mr-auto" data-aos="fade" data-aos-duration="800">
              <div className="relative flex flex-col w-full">
                <h2 className="flex w-full text-2xl font-thin text-white 2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-3xl">
                  신안은&nbsp;<div className="font-semibold">소멸 위기 지역</div>&nbsp;입니다.
                </h2>
              </div>
            </div>

            <p className='relative z-30 text-4xl text-white sm:text-6xl left-5 sm:left-3 top-6 font-jaram' data-aos="fade" data-aos-duration="800">#problems</p>
            
            <div className='justify-between w-full gap-10 px-4 md:flex'>
              <div className='relative flex flex-col w-full md:w-4/12 rounded-2xl' data-aos="fade-up" data-aos-duration="600">
                <div className='bg-gray-950'>
                  <img src={ship} className='object-cover w-full h-72 rounded-2xl opacity-40'/>
                </div>
                <div className='absolute left-0 flex flex-col w-full top-20 h-72'>
                  <span className='mx-auto text-2xl font-semibold text-white xl:text-3xl lg:text-2xl md:text-xl sm:text-2xl'>연안 교통 인프라 부족</span>
                  <span className='px-4 mx-auto mt-5 text-base text-center text-white 2xl:text-xl xl:text-lg md:text-sm sm:text-lg'>
                    <p className='mb-1'>안전한 이동을 위한 교통정보 및 안내 시스템 부족</p>
                    <p className='mb-1'>인접 도시를 연결하는 광역교통망 정보 불균형</p>
                    <p>도서지역 주민의 이동권 침해 및 지역 단절 심화</p>
                  </span>
                </div>
                <span className='mt-3 text-3xl text-center text-white lg:text-3xl md:text-2xl sm:text-3xl font-jaram'>#한정된_교통수단</span>
              </div>

              <div className='relative flex flex-col w-full mt-6 md:mt-0 sm:mt-10 md:w-4/12 rounded-2xl' data-aos="fade-up" data-aos-duration="1000">
                <div className='bg-gray-950'>
                  <img src={notice} className='object-cover w-full h-72 rounded-2xl opacity-40'/>
                </div>
                <div className='absolute left-0 flex flex-col w-full top-20 h-72'>
                  <span className='mx-auto text-2xl font-semibold text-white xl:text-3xl lg:text-2xl md:text-xl sm:text-2xl'>결항 정보 안내 부족</span>
                  <span className='px-4 mx-auto mt-5 text-base text-center text-white 2xl:text-xl xl:text-lg md:text-sm sm:text-lg'>
                    <p className='mb-1'>20여 개 항로의 통합 관리시스템 부재</p>
                    <p className='mb-1'>잦은 여객선 결항에도 정보 전달 체계 부재</p>
                    <p>여객선 이용시 대기시간 예측 불가</p>
                  </span>
                </div>
                <span className='mt-3 text-3xl text-center text-white lg:text-3xl md:text-2xl sm:text-3xl font-jaram'>#불확실한_도착시간</span>
              </div>

              <div className='relative flex flex-col w-full mt-6 md:mt-0 sm:mt-10 md:w-4/12 rounded-2xl' data-aos="fade-up" data-aos-duration="1400">
                <div className='bg-gray-950'>
                  <img src={foreshore} className='object-cover w-full h-72 rounded-2xl opacity-40'/>
                </div>
                <div className='absolute left-0 flex flex-col w-full top-20 h-72'>
                  <span className='mx-auto text-2xl font-semibold text-white xl:text-3xl lg:text-2xl md:text-xl sm:text-2xl'>노두길 · 갯벌 사고 빈번</span>
                  <span className='px-4 mx-auto mt-5 text-base text-center text-white 2xl:text-xl xl:text-lg md:text-sm sm:text-lg'>
                    <p className='mb-1'>조수간만의 차로 발생하는 노두길에 대한 이해 부족</p>
                    <p className='mb-1'>노두길이 잠길 때, 섬 내 고립 및 이동 제약</p>
                    <p>갯벌의 지형적 특징으로 사고 발생 시 대피에 취약</p>
                  </span>
                </div>
                <span className='mt-3 text-3xl text-center text-white lg:text-3xl md:text-2xl sm:text-3xl font-jaram'>#빈번한_안전사고</span>
              </div>
            </div>    
          </div>
        </div>
      </section>
      

      {/* <section className="xl:pt-20 xl:pb-20 lg:pt-10 lg:pb-10 md:pt-10 md:pb-10 bg-bg1"> */}
      <section className="py-10 2xl:py-32 lg:py-24 md:py-20 sm:py-24 bg-product3">
        <div className="container mx-auto">
          <div className="flex flex-wrap">

            <div className="w-full px-4 mb-10 md:w-6/12 md:mb-0" data-aos="fade" data-aos-duration="800">
              <div className="relative flex flex-col w-full">
                <h2 className="flex w-full text-2xl font-semibold text-gray-800 2xl:mb-10 xl:mb-6 lg:mb-4 md:mb-2 sm:mb-2 2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-3xl">
                  <div className="text-blue-800">천사뱃길</div>에서
                </h2>

                <p className='text-sm text-gray-600 2xl:leading-10 xl:leading-8 lg:leading-7 md:leading-5 sm:leading-6 2xl:text-2xl xl:text-xl lg:text-xl md:text-md'>
                  신안군 주민들과 여행객들에게 정확한 정보를 전달해드립니다.
                </p>
              </div>
            </div>

            <div className="w-full px-4 ml-auto mr-auto md:w-6/12">
              <div className="relative flex flex-wrap justify-center">
                <div className="w-full">
                    <div className="flex flex-col p-8 bg-white rounded-lg shadow-lg h-44" data-aos="fade-up" data-aos-duration="800">
                      <div><i class="fa-solid fa-sitemap fa-2xl" style={{color: "#1e40af"}}></i></div>
                      <p className="mt-4 text-lg font-semibold text-gray-800">여객선 도착 정보 안내 시스템 구축</p>
                      <p className="mt-1 text-sm font-medium text-gray-600">
                        여객선 실시간 위치와 도착 예정 시간 및 결항 여부를 전달합니다.</p>
                    </div>
                    <div className="flex flex-col justify-center p-8 mt-8 bg-white rounded-lg shadow-lg h-44" data-aos="fade-up" data-aos-duration="800">
                      <div><i class="fa-solid fa-traffic-light fa-2xl" style={{color: "#1e40af"}}></i></div>
                      <p className="mt-4 text-lg font-semibold text-gray-800">노두길 신호 체계 개발</p>
                      <p className="mt-1 text-sm font-medium text-gray-600">
                      실시간 노두길 통행 가능 여부를 알려드립니다.</p>
                    </div>
                    <div className="flex flex-col justify-center p-8 mt-8 bg-white rounded-lg shadow-lg h-44" data-aos="fade-up" data-aos-duration="800">
                      <div><i class="fa-solid fa-circle-info fa-2xl" style={{color: "#1e40af"}}></i></div>
                      <p className="mt-4 text-lg font-semibold text-gray-800">섬지역 안전 정보 통합 정리</p>
                      <p className="mt-1 text-sm font-medium text-gray-600">
                      안전사고 주의 구역, 유의 사항, 편의 시설 등 다양한 정보를 한 플래폼에서 제공해드립니다.</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      
      <section className="py-10 bg-white 2xl:py-32 lg:py-24 md:py-20 sm:py-24">
        <div className="container mx-auto">
          <div className="flex flex-col ">

            <div className="w-full px-4 mb-10 text-center md:mb-0" data-aos="fade" data-aos-duration="800">
              <div className="relative flex flex-col">
                <h2 className="flex mx-auto text-4xl font-semibold text-gray-800 2xl:mb-16 xl:mb-11 lg:mb-7 md:mb-5 sm:mb-4 2xl:text-7xl xl:text-6xl sm:text-5xl font-jaram">#1</h2>
                <h2 className="flex mx-auto text-2xl font-semibold text-blue-800 2xl:mb-10 xl:mb-6 lg:mb-4 md:mb-2 sm:mb-2 2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-3xl">
                  여객선 도착 정보 안내 시스템 구축
                </h2>
                <p className='text-sm text-gray-600 2xl:leading-10 xl:leading-8 lg:leading-7 md:leading-5 sm:leading-6 2xl:text-2xl xl:text-xl lg:text-xl md:text-md'>
                  여객선 실시간 위치, 도착 시간 및 결항 여부를 전달합니다.
                </p>
              </div>
            </div>

            <div className='flex flex-col justify-center w-full px-4 mt-0 sm:mt-20 md:flex-row sm:flex-col'>
              <div className="relative flex w-full min-w-0 overflow-hidden break-words md:w-6/12 sm:w-full md:flex-col sm:flex" data-aos="fade-right" data-aos-duration="600">
                <img src={mockup2} className='w-3/6 mx-auto'/>
                <div className='w-full pl-5 my-auto text-sm md:pr-16 md:mt-8 md:text-center sm:pl-8 sm:pr-0 sm:text-left sm:my-auto lg:text-base sm:text-lg md:text-sm 2xl:text-2xl xl:text-xl'>
                  항구에 설치된 키오스크 디스플레이로<br/>
                  운항중인 선박의 위치와 도착 예정 시간,<br/>
                  만조시간, 물때를 알 수 있습니다. 
                </div>
              </div>
              <div className="relative flex w-full min-w-0 mt-10 overflow-hidden break-words md:w-6/12 md:mt-0 sm:mt-20 md:flex-col sm:w-full sm:flex" data-aos="fade-right" data-aos-duration="1000">
                <img src={mockup3} className='w-3/6 mx-auto'/>
                <div className='w-full pl-5 my-auto text-sm md:pl-16 sm:pl-8 md:mt-8 md:text-center sm:text-left sm:my-auto lg:text-base md:text-sm sm:text-lg 2xl:text-2xl xl:text-xl'>
                모바일 애플리케이션으로 선박의 위치와<br/>
                도착 예정 시간, 노두길 신호체계,<br/>안전 정보를 알 수 있습니다.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr></hr>


      <section className="pt-10 pb-5 bg-white 2xl:pt-32 2xl:pb-16 lg:pt-24 lg:pb-12 md:pt-20 md:pb-10 sm:pt-24 sm:pb-12">
        <div className="container mx-auto">
          <div className="flex flex-col ">

            <div className="w-full px-4 text-center md:mb-0" data-aos="fade" data-aos-duration="800">
              <div className="relative flex flex-col">
                <h2 className="flex mx-auto text-4xl font-semibold text-gray-800 2xl:mb-16 xl:mb-11 lg:mb-7 md:mb-5 sm:mb-4 2xl:text-7xl xl:text-6xl sm:text-5xl font-jaram">#2</h2>
                <h2 className="flex mx-auto text-2xl font-semibold text-blue-800 2xl:mb-10 xl:mb-6 lg:mb-4 md:mb-2 sm:mb-2 2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-3xl">
                  노두길 신호 체계 개발
                </h2>
                <p className='text-sm text-gray-600 2xl:leading-10 xl:leading-8 lg:leading-7 md:leading-5 sm:leading-6 2xl:text-2xl xl:text-xl lg:text-xl md:text-md'>
                  실시간 노두길 통행 가능 여부를 알려드립니다.
                </p>
              </div>
            </div>

            <div className='flex justify-center w-full gap-10 mt-10 mb-10 md:mb-0 md:mt-20 xl:gap-56 md:gap-40'>
              <div className="relative w-3/12 min-w-0 overflow-hidden break-words" data-aos="fade-up" data-aos-duration="600">
                <img src={mockup4}/>
              </div>
              <div className="relative w-3/12 min-w-0 overflow-hidden break-words" data-aos="fade-up" data-aos-duration="1000">
                <img src={mockup5}/>
              </div>
            </div>
            <div className='w-full px-4 text-sm text-center md:pr-16 xl:mt-20 md:mt-10 sm:pl-8 sm:pr-0 sm:my-auto lg:text-base sm:text-lg md:text-sm 2xl:text-2xl xl:text-xl'>
              노두길 통행 안내를 위한 신호 체계 구축해서<br/>
              통행이 불가능한 시간을 모바일 애플리케이션을 통해 예보합니다.
            </div>
          </div>
        </div>
      </section>

      
      <section className="px-4 py-5 bg-gray-50 2xl:py-16 lg:py-12 md:py-10 sm:py-12">
        <div className="container w-full mx-auto md:w-10/12 sm:w-full">
          <div className="flex flex-col ">
          <div className="text-2xl text-gray-500 xl:text-5xl lg:text-4xl sm:text-3xl font-jaram">Q. 노두길이란?</div>
          <span className='mt-3 text-sm text-gray-400 lg:text-base sm:text-base md:text-sm 2xl:text-2xl xl:text-xl'>
            교통과 다리가 발달하기 전, 섬과 육지를 연결하기 위해 직접 돌을 쌓아 만든 길로, 높이가 낮아 썰물에 드러나고, 밀물에 잠기기 때문에 노두길을 건넜다가 섬이나 바다에 고립되는 일이 많다.
          </span>
          </div>
        </div>
      </section>
      <hr></hr>


      <section className="pt-10 bg-white 2xl:pt-32 lg:pt-24 md:pt-20 sm:pt-24">
        <div className="container mx-auto">
          <div className="flex flex-col ">

            <div className="w-full px-4 text-center md:mb-0" data-aos="fade" data-aos-duration="800">
              <div className="relative flex flex-col">
                <h2 className="flex mx-auto text-4xl font-semibold text-gray-800 2xl:mb-16 xl:mb-11 lg:mb-7 md:mb-5 sm:mb-4 2xl:text-7xl xl:text-6xl sm:text-5xl font-jaram">#3</h2>
                <h2 className="flex mx-auto text-2xl font-semibold text-blue-800 2xl:mb-10 xl:mb-6 lg:mb-4 md:mb-2 sm:mb-2 2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-3xl">
                  섬 지역 안전 정보 통합 정리
                </h2>
                <p className='mb-10 text-sm text-gray-600 xl:mb-20 sm:mb-10 2xl:leading-10 xl:leading-8 lg:leading-7 md:leading-5 sm:leading-6 2xl:text-2xl xl:text-xl lg:text-xl md:text-md'>
                  안전사고 주의 구역, 유의 사항, 주변 편의시설 등<br/>
                  거주·여행 시 필요한 정보를 한 플랫폼에서 제공합니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

            {/* 무한 슬라이더 */}
      <div className='relative px-0 overflow-hidden md:pb-96 sm:pb-60 pb-60'>
        <div className='absolute flex flex-row mb-10 md:animate-slider sm:animate-slider2 animate-slider2'>
          <img className='mr-3 md:mr-5 sm:mr-3 rounded-2xl md:h-96 sm:h-60 h-60' src={fishacc}/>
          <img className='mr-3 md:mr-5 sm:mr-3 rounded-2xl md:h-96 sm:h-60 h-60' src={fishacc2}/>
          <img className='mr-3 md:mr-5 sm:mr-3 rounded-2xl md:h-96 sm:h-60 h-60' src={seaacc}/>
          <img className='mr-3 md:mr-5 sm:mr-3 rounded-2xl md:h-96 sm:h-60 h-60' src={seaacc2}/>
          <img className='mr-3 md:mr-5 sm:mr-3 rounded-2xl md:h-96 sm:h-60 h-60' src={shipacc}/>
          <img className='mr-3 md:mr-5 sm:mr-3 rounded-2xl md:h-96 sm:h-60 h-60' src={shipacc2}/>
          <img className='mr-3 md:mr-5 sm:mr-3 rounded-2xl md:h-96 sm:h-60 h-60' src={swimacc}/>
          <img className='mr-3 md:mr-5 sm:mr-3 rounded-2xl md:h-96 sm:h-60 h-60' src={swimacc2}/>
          
          <img className='mr-3 md:mr-5 sm:mr-3 rounded-2xl md:h-96 sm:h-60 h-60' src={fishacc}/>
          <img className='mr-3 md:mr-5 sm:mr-3 rounded-2xl md:h-96 sm:h-60 h-60' src={fishacc2}/>
          <img className='mr-3 md:mr-5 sm:mr-3 rounded-2xl md:h-96 sm:h-60 h-60' src={seaacc}/>
          <img className='mr-3 md:mr-5 sm:mr-3 rounded-2xl md:h-96 sm:h-60 h-60' src={seaacc2}/>
          <img className='mr-3 md:mr-5 sm:mr-3 rounded-2xl md:h-96 sm:h-60 h-60' src={shipacc}/>
          <img className='mr-3 md:mr-5 sm:mr-3 rounded-2xl md:h-96 sm:h-60 h-60' src={shipacc2}/>
          <img className='mr-3 md:mr-5 sm:mr-3 rounded-2xl md:h-96 sm:h-60 h-60' src={swimacc}/>
          <img className='mr-3 md:mr-5 sm:mr-3 rounded-2xl md:h-96 sm:h-60 h-60' src={swimacc2}/>
        </div>
      </div>

      <div className='w-full px-4 pb-24 mt-10 text-sm text-center sm:pb-20 md:pr-16 xl:mt-20 sm:mt-10 sm:pl-8 sm:pr-0 sm:my-auto lg:text-base sm:text-lg md:text-sm 2xl:text-2xl xl:text-xl'>
        천사뱃길 모바일 애플리케이션에서 다양한 안전 정보를 확인할 수 있습니다. 
      </div>
          
    </>
  );
}

AOS.init();
export default Product