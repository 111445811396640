import React from 'react';

function Footer() {
  return (
    <>
      <footer className="block pt-5 pb-10 bg-gray-600 xl:pt-20 xl:pb-28 lg:pt-20 lg:pb-28 md:pt-14 md:pb-20 sm:pt-10 sm:pb-14">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap items-center justify-center md:justify-between">
            <div className="w-full px-4 md:w-4/12">
              <div className="py-1 text-sm font-light text-center text-gray-500 md:text-left">
                Copyright © {new Date().getFullYear()}{" "}
                <a
                  href="/"
                  className="py-1 text-sm font-light text-gray-500"
                >
                  코끼리협동조합
                </a>
              </div>
            </div>
            <div className="w-full px-4 md:w-8/12">
              <ul className="flex flex-wrap justify-center list-none md:justify-end">
                <li>
                  <a
                    href="/product"
                    className="block px-3 py-1 text-sm font-light text-gray-500 hover:text-gray-400"
                  >
                    Product
                  </a>
                </li>
                <li>
                  <a
                    href="/usecase"
                    className="block px-3 py-1 text-sm font-light text-gray-500 hover:text-gray-400"
                  >
                    Use Case
                  </a>
                </li>
                <li>
                  <a
                    href="/about"
                    className="block px-3 py-1 text-sm font-light text-gray-500 hover:text-gray-400"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="/contact"
                    className="block px-3 py-1 text-sm font-light text-gray-500 hover:text-gray-400"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer