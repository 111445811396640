import React from 'react'
import styled from "styled-components";
import { Link } from "react-router-dom";
import home1 from "../assets/img/home1.jpg"
import home2 from "../assets/img/home2.jpg"
import home3 from "../assets/img/home3.jpg"
import home4 from "../assets/img/home4.jpg"
import home5 from "../assets/img/home5.jpg"

import apple from "../assets/img/apple.png"
import playstore from "../assets/img/playstore.png"

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import '../assets/styles/swiper.css';
import { Mousewheel, Pagination } from 'swiper/modules';


const Index = styled.div`
// background-color: red;
height: 100%;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 2;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  font-size: 4rem;
  font-weight: 600;
  line-height: 5rem;

  @media (max-width: 768px) {
    font-size: 2.5rem;
    line-height: 3rem;
  }
`;

const Downloads = styled.div`
  display: flex;
  gap: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0rem;
  }
`;
const DownloadBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  height: 5rem;
  width: 18rem;
  border-radius: 1rem;
  box-shadow: 0 0 0 2px white inset; 
  transition: background-color 200ms ease-in-out;
  background-color: rgba(255, 255, 255, 0.1);
  &:hover {
    background-color: #172554;
    box-shadow: none;
  }
  .apple {
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
  }
  .playstore {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 3rem;
    width: 12rem;
    height: 3.5rem;
    margin-top: 2rem;
    .apple {
      width: 2rem;
      height: 2rem;
    }
    .playstore {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

const ViewBtn = styled.button`
  margin-top: 3rem;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  width: 18rem;
  height: 5rem;
  border-radius: 1rem;
  box-shadow: 0 0 0 2px white inset; 
  transition: background-color 200ms ease-in-out;
  background-color: rgba(255, 255, 255, 0.1);
  &:hover {
    background-color: #172554;
    box-shadow: none;
  }
  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 3rem;
    width: 12rem;
    height: 3.5rem;
    margin-top: 2rem;
  }
`;

const TextSmall = styled.div`
  position: absolute;
  z-index: 100;
  text-align: center;
  line-height: 1.5rem;
  font-weight: 500;
  font-size: 1rem;
  color: white;
  bottom: 0;
  margin-bottom: 5rem;
  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

export default () => {
  return (
    <Index>

      <Swiper
        direction={'vertical'}
        slidesPerView={1}
        mousewheel={true}
        loop={true}
        pagination={{
          clickable: true,
        }}
        modules={[Mousewheel, Pagination]}
        className="mySwiper"
        speed={800}
      >
        <SwiperSlide>
          <Text>
            신안 여객선<br/>
            실시간 도착 정보
            <Downloads>
              <a href="https://apps.apple.com/kr/app/%EC%B2%9C%EC%82%AC%EB%B1%83%EA%B8%B8/id1613130239?platform=ipad">
                <DownloadBtn><img src={apple} className='apple'/>App Store</DownloadBtn></a>
              <a href="https://play.google.com/store/apps/details?id=com.co77iri.safe_island">
                <DownloadBtn><img src={playstore} className='playstore'/>Play Store</DownloadBtn></a>
            </Downloads>
          </Text>
          <TextSmall>
            천사뱃길은 신안 여객선의 도착정보, 갯벌의 통행 신호 체계,<br/>
            신안 및 섬 지역의 안전 정보 등을 제공하는 플랫폼 입니다.
          </TextSmall>
          <img src={home1} alt="" />
        </SwiperSlide>

        <SwiperSlide>
          <Text>
            Product
            <Link to="/product">
              <ViewBtn>View More</ViewBtn>
            </Link>
          </Text>
          <TextSmall>
            천사뱃길은 신안 여객선의 도착정보, 갯벌의 통행 신호 체계,<br/>
            신안 및 섬 지역의 안전 정보 등을 제공하는 플랫폼 입니다.
          </TextSmall>
          <img src={home2} alt="" />
        </SwiperSlide>

        {/* <SwiperSlide>
          <Text>
            Use Case
            <Link to="/usecase">
              <ViewBtn>View More</ViewBtn>
            </Link>
          </Text>
          <TextSmall>
            천사뱃길은 신안 여객선의 도착정보, 갯벌의 통행 신호 체계,<br/>
            신안 및 섬 지역의 안전 정보 등을 제공하는 플랫폼 입니다.
          </TextSmall>
          <img src={home3} alt="" />
        </SwiperSlide> */}
        
        <SwiperSlide>
          <Text>
            About
            <Link to="/about">
              <ViewBtn>View More</ViewBtn>
            </Link>
          </Text>
          <TextSmall>
            천사뱃길은 신안 여객선의 도착정보, 갯벌의 통행 신호 체계,<br/>
            신안 및 섬 지역의 안전 정보 등을 제공하는 플랫폼 입니다.
          </TextSmall>
          <img src={home4} alt="" />
        </SwiperSlide>

        <SwiperSlide>
          <Text>
            Contact
            <Link to="contact">
              <ViewBtn>View More</ViewBtn>
            </Link>
          </Text>
          <TextSmall>
            천사뱃길은 신안 여객선의 도착정보, 갯벌의 통행 신호 체계,<br/>
            신안 및 섬 지역의 안전 정보 등을 제공하는 플랫폼 입니다.
          </TextSmall>
          <img src={home5} alt="" />
        </SwiperSlide>
      </Swiper>
    </Index>
  );
};