import React from 'react'
import { Link } from "react-router-dom";
import "../../assets/styles/index.css"
import logoImg from "../../assets/img/logo.svg"

function HomeNavbar() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <nav className="fixed top-0 z-50 flex flex-wrap items-center justify-between w-full px-2 py-3 font-sans border-white bg-blue-950 border-opacity-30 border-1 lg:border-b lg:bg-opacity-0 navbar-expand-lg">
        <div className="container flex flex-wrap items-center justify-between px-4 mx-auto">
          <div className="relative flex justify-between w-full lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/"
              className="inline-block py-2 mr-4 leading-relaxed whitespace-nowrap"
            >
              <img src={logoImg} alt="logo" className='h-6'/>
            </Link>
            <button
              className="block px-3 py-1 text-xl leading-none bg-transparent border-transparent outline-none cursor-pointer lg:hidden focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars" style={{color: "white"}}></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center lg:bg-opacity-0 lg:shadow-none z-50" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >

            <ul className="flex flex-col list-none lg:flex-row lg:ml-auto">
              <li className="flex items-center justify-center">
                <Link to="/">
                  <span className="flex items-center px-3 py-4 text-sm font-bold text-white uppercase lg:py-2">Home</span>
                </Link>
              </li>

              <li className="flex items-center justify-center">
                <Link to="/product">
                  <span className="flex items-center px-3 py-4 text-sm text-white uppercase hover:font-bold">Product</span>
                </Link>
              </li>

              {/* <li className="flex items-center justify-center">
                <Link to="/usecase">
                  <span className="flex items-center px-3 py-4 text-sm text-white uppercase lg:py-2 hover:font-bold">Use Case</span>
                </Link>
              </li> */}

              <li className="flex items-center justify-center">
                <Link to="/about">
                  <span className="flex items-center px-3 py-4 text-sm text-white uppercase lg:py-2 hover:font-bold">About</span>
                </Link>
              </li>

              <li className="flex items-center justify-center">
                <Link to="contact">
                  <span className="flex items-center px-3 py-4 text-sm text-white uppercase lg:py-2 hover:font-bold">Contact</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
  )
}

export default HomeNavbar