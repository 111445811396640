// Home
import React from 'react'
import { Outlet } from 'react-router-dom';
import HomeNavbar from '../components/Navbar/HomeNavbar';
import Home from "../views/Home";

import styled from "styled-components";
const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`;
const MainContent = styled.div`
  flex: 1;
  height: 100vh;
`;

function Layout() {
  return (
    <LayoutContainer>
      <HomeNavbar />
      
      <MainContent>
        <Home />
      </MainContent>

    </LayoutContainer>
  );
};

export default Layout;