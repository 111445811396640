// Product
import React from 'react'
import { Outlet } from 'react-router-dom';
import Navbar from "../components/Navbar/Navbar";
import Header from "../components/Navbar/Header";
import Footer from "../components/Footer/Footer";

import styled from "styled-components";

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`;

const MainContent = styled.div`
flex: 1;
`;

function Layout() {
  return (
    <LayoutContainer>
      <Navbar />
      <Header />
      
      <MainContent>
        <Outlet />
      </MainContent>

      <Footer />
    </LayoutContainer>
  );
};

export default Layout;