import React from 'react'
import Header from "../components/Navbar/Header";

import about1 from "../assets/img/about1.png";
import about2 from "../assets/img/about2.png";
import about3 from "../assets/img/about3.png";
import about4 from "../assets/img/about4.png";

import AOS from 'aos';
import 'aos/dist/aos.css';

function About() {
  return (
    <>
    <section className="py-10 mt-32 bg-white sm:py-20 xl:mt-64 lg:mt-64 md:mt-48 sm:mt-32">
      <div className="container mx-auto">
        <div className="flex flex-wrap items-center">
          
          {/* <div className="w-full mt-0 mb-5 ml-auto mr-auto 2xl:pl-20 lg:pl-10 md:pl-5 md:w-6/12 xl:mt-0 xl:mb-0 lg:mt-0 lg:mb-0 md:mt-0 md:mb-0 sm:mt-0 sm:mb-10">
            <div className="relative flex flex-col w-full px-4 ml-auto mr-auto animate-fade">
              <p className="mb-2 text-2xl font-semibold 2xl:mb-10 xl:mb-6 lg:mb-4 md:mb-2 sm:mb-2 2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-3xl">
                <p className='flex 2xl:mb-3 xl:mb-2 lg:mb-2 md:mb-0'>
                  <div className="text-blue-800">천사뱃길</div>은
                </p>
                이런 과정으로 만들어졌다
              </p>
              <p className='text-sm text-gray-600 2xl:leading-10 xl:leading-8 lg:leading-7 md:leading-5 sm:leading-6 2xl:text-xl xl:text-lg lg:text-lg md:text-base'>
                2021. 한국지역정보개발원 주민참여 디지털 기반 지역사회 현장 문제해결 사업 선정 - 행정안전부 우수상 수상<br />
                2022. 병풍도, 기점·소악도 내 안내 디스플레이 설치
              </p>
            </div>
          </div> */}

          <div className="flex w-8/12 mx-auto md:mt-0 lg:mt-0 md:w-6/12 lg:w-6/12 xl:mb-0 lg:mb-0 md:mb-0 animate-fade">
            <div className="relative flex flex-col w-full min-w-0 break-words">
              <div className='aspect-w-16 aspect-h-9'>
                <iframe className='rounded-2xl' width="560" height="315" src="https://www.youtube.com/embed/bjE4pfnFMUw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <p className='px-4 mx-auto mt-10 text-sm text-gray-600 2xl:leading-10 xl:leading-8 lg:leading-7 md:leading-5 sm:leading-6 2xl:text-xl xl:text-lg lg:text-lg md:text-base'>
            2021. 한국지역정보개발원 주민참여 디지털 기반 지역사회 현장 문제해결 사업 선정 - 행정안전부 우수상 수상<br />
            2022. 병풍도, 기점·소악도 내 안내 디스플레이 설치
          </p>

        </div>
      </div>
    </section>

    <section className="py-10 bg-bg1 sm:py-20">
      <div className="container mx-auto">
        <div className="flex flex-wrap items-center">
          <div className="flex w-8/12 ml-auto mr-auto lg:pl-20 md:mt-0 lg:mt-0 md:w-6/12 lg:w-6/12 xl:mb-0 lg:mb-0 md:mb-0">
            <div className="relative flex flex-col w-full min-w-0 break-words" data-aos="fade-right" data-aos-duration="800">
              <img
                src={about1}
                // className='shadow-lg rounded-2xl'
              />
            </div>
          </div>
          <div className="w-full mt-5 ml-auto mr-auto 2xl:pl-20 lg:pl-10 md:pl-5 md:w-6/12 xl:mt-0 xl:mb-0 lg:mt-0 lg:mb-0 md:mt-0 md:mb-0 sm:mt-10 sm:mb-0" data-aos="fade-up" data-aos-duration="800">
            <div className="relative flex flex-col items-end w-full px-4 ml-auto mr-auto text-end">
              <p className='mr-1 text-sm 2xl:text-2xl xl:text-xl lg:text-lg md:text-base sm:text-sm'>01</p>
              <p className="mb-2 text-2xl font-semibold 2xl:mb-10 xl:mb-6 lg:mb-4 md:mb-2 sm:mb-2 2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-3xl">
                지역 문제 발굴</p>
              <hr className='w-16 h-1 mb-2 bg-blue-800 border-0 2xl:w-32 xl:w-24 lg:w-20 md:w-16 sm:w-20 2xl:h-2 xl:h-2 lg:h-1 2xl:mb-10 xl:mb-6 lg:mb-4 md:mb-2 sm:mb-2 rounded-2xl' ></hr>
              <p className='text-sm text-gray-600 2xl:leading-10 xl:leading-8 lg:leading-7 md:leading-5 sm:leading-6 2xl:text-2xl xl:text-xl lg:text-xl md:text-base'>
                신안군 지역 주민 워크숍,<br/>
                지자체 간담회를 통해<br/>
                지역 문제를 발굴했습니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <hr />

    <section className="py-10 bg-bg1 sm:py-20">
      <div className="container mx-auto">
        <div className="flex flex-wrap items-center">
        <div className="flex w-8/12 ml-auto mr-auto lg:pl-20 md:mt-0 lg:mt-0 md:w-6/12 lg:w-6/12 xl:mb-0 lg:mb-0 md:mb-0">
            <div className="relative flex flex-col w-full min-w-0 break-words" data-aos="fade-right" data-aos-duration="800">
              <img
                src={about2}
                // className='shadow-lg rounded-2xl'
              />
            </div>
          </div>
          <div className="w-full mt-5 ml-auto mr-auto 2xl:pl-20 lg:pl-10 md:pl-5 md:w-6/12 xl:mt-0 xl:mb-0 lg:mt-0 lg:mb-0 md:mt-0 md:mb-0 sm:mt-10 sm:mb-0" data-aos="fade-up" data-aos-duration="800">
            <div className="relative flex flex-col items-end w-full px-4 ml-auto mr-auto text-end">
              <p className='mr-1 text-sm 2xl:text-2xl xl:text-xl lg:text-lg md:text-base sm:text-sm'>02</p>
              <p className="mb-2 text-2xl font-semibold 2xl:mb-10 xl:mb-6 lg:mb-4 md:mb-2 sm:mb-2 2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-3xl">
                안전 지도 개발</p>
              <hr className='w-16 h-1 mb-2 bg-blue-800 border-0 2xl:w-32 xl:w-24 lg:w-20 md:w-16 sm:w-20 2xl:h-2 xl:h-2 lg:h-1 2xl:mb-10 xl:mb-6 lg:mb-4 md:mb-2 sm:mb-2 rounded-2xl' ></hr>
              <p className='text-sm text-gray-600 2xl:leading-10 xl:leading-8 lg:leading-7 md:leading-5 sm:leading-6 2xl:text-2xl xl:text-xl lg:text-xl md:text-base'>
                신안군 주민 주도로<br/>
                병풍, 기점, 소악도의<br/>
                안전 지도를 개발했습니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <hr />

    <section className="pt-10 pb-24 bg-bg1 sm:py-20">
      <div className="container mx-auto">
        <div className="flex flex-wrap items-center">
          <div className="flex w-8/12 ml-auto mr-auto lg:pl-20 md:mt-0 lg:mt-0 md:w-6/12 lg:w-6/12 xl:mb-0 lg:mb-0 md:mb-0">
            <div className="relative flex flex-col w-full min-w-0 break-words" data-aos="fade-right" data-aos-duration="800">
              <img
                src={about3}
                // className='shadow-lg rounded-2xl'
              />
            </div>
          </div>
          <div className="w-full mt-5 ml-auto mr-auto 2xl:pl-20 lg:pl-10 md:pl-5 md:w-6/12 xl:mt-0 xl:mb-0 lg:mt-0 lg:mb-0 md:mt-0 md:mb-0 sm:mt-10 sm:mb-0" data-aos="fade-up" data-aos-duration="800">
            <div className="relative flex flex-col items-end w-full px-4 ml-auto mr-auto text-end">
              <p className='mr-1 text-sm 2xl:text-2xl xl:text-xl lg:text-lg md:text-base sm:text-sm'>03</p>
              <p className="mb-2 text-2xl font-semibold 2xl:mb-10 xl:mb-6 lg:mb-4 md:mb-2 sm:mb-2 2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-3xl">
                시범 운영</p>
              <hr className='w-16 h-1 mb-2 bg-blue-800 border-0 2xl:w-32 xl:w-24 lg:w-20 md:w-16 sm:w-20 2xl:h-2 xl:h-2 lg:h-1 2xl:mb-10 xl:mb-6 lg:mb-4 md:mb-2 sm:mb-2 rounded-2xl' ></hr>
              <p className='text-sm text-gray-600 2xl:leading-10 xl:leading-8 lg:leading-7 md:leading-5 sm:leading-6 2xl:text-2xl xl:text-xl lg:text-xl md:text-base'>
                현재 신안군, 송도, 송공 등<br/>
                2개 항구에 시범 운영 중입니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default About