import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeLayout from "./layouts/HomeLayout";
import Layout from "./layouts/Layout";

import Product from "./views/Product";
import Usecase from "./views/Usecase";
import About from "./views/About";
import Contact from "./views/Contact";

function App() {
  return (
    <Router>
      <Routes>
        {/* 홈 페이지 */}
        <Route path="/" element={<HomeLayout />} />

        <Route path="/product" element={<Layout />}>
          <Route index element={<Product />} />
        </Route>
        <Route path="/usecase" element={<Layout />}>
          <Route index element={<Usecase />} />
        </Route>
        <Route path="/about" element={<Layout />}>
          <Route index element={<About />} />
        </Route>
        <Route path="/contact" element={<Layout />}>
          <Route index element={<Contact />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;