import React from 'react'
import Header from "../components/Navbar/Header";

import use from "../assets/img/use.jpg";

function Usecase() {
  return (
    <>
    <section className="py-10 mt-32 bg-white sm:py-20 xl:mt-64 lg:mt-64 md:mt-48 sm:mt-32">
      <div className="container mx-auto">
        <div className="flex flex-wrap items-center">
          <div className="flex w-8/12 ml-auto mr-auto lg:pl-20 md:mt-0 lg:mt-0 md:w-6/12 lg:w-6/12 xl:mb-0 lg:mb-0 md:mb-0 animate-fade-right">
            <div className="relative flex flex-col w-full min-w-0 break-words">
              <img
                src={use}
                className='shadow-lg rounded-2xl'
              />
            </div>
          </div>
          <div className="w-full mt-5 ml-auto mr-auto 2xl:pl-20 lg:pl-10 md:pl-5 md:w-6/12 xl:mt-0 xl:mb-0 lg:mt-0 lg:mb-0 md:mt-0 md:mb-0 sm:mt-10 sm:mb-0">
            <div className="relative flex flex-col w-full px-4 ml-auto mr-auto animate-fade">
              <p className="flex mb-2 text-2xl font-semibold 2xl:mb-10 xl:mb-6 lg:mb-4 md:mb-2 sm:mb-2 2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-3xl">
                전라남도 신안군</p>
              <p className='text-sm text-gray-600 2xl:leading-10 xl:leading-8 lg:leading-7 md:leading-5 sm:leading-6 2xl:text-2xl xl:text-xl lg:text-xl md:text-base'>
                천사뱃길은 신안 여객선의 도착정보, 갯벌의 통행 신호 체계,<br/>
                신안 및 섬 지역의 안전 정보 등을 제공하는 플랫폼입니다.
              </p>
              <div className='mt-5 2xl:mt-30 xl:mt-24 lg:mt-4 md:mt-7 sm:mt-7'>
                <hr className='mb-3 xl:mb-5 lg:mb-2 md:mb-2 sm:mb-2' ></hr>
                <p className='text-xs text-gray-400 2xl:text-base xl:text-sm lg:text-sm md:text-xs'>
                  2021. 한국지역정보개발원 주민참여 디지털 기반 지역사회 현장 문제해결 사업 선정 - 행정안전부 우수상 수상<br />
                  2022. 병풍도, 기점·소악도 내 안내 디스플레이 설치
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Usecase