import React, { useState, useEffect } from 'react'
import angel from "../../assets/img/1004.png"
import { useLocation } from 'react-router-dom';

function Header() {
    const location = useLocation();
    let title, description;

    // URL 경로에 따라 제목과 설명을 조정
    switch (location.pathname) {
        case '/product':
            title = 'Product';
            description = '천사뱃길 app 소개';
            break;
        // case '/usecase':
        //     title = 'Use Case';
        //     description = '전라남도 신안군 병풍도, 기점·소악도 설치';
        //     break;
        case '/about':
            title = 'About';
            description = '천사뱃길 제작 과정';
            break;
        case '/contact':
            title = 'Contact';
            description = '코끼리협동조합';
            break;
    }

    // 스크롤 이벤트
    const [scrollPosition, setScrollPosition] = useState(0);
    const updateScroll = () => {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    }
    useEffect(() => {
        window.addEventListener('scroll', updateScroll);
    });

    const headerOpacity = scrollPosition >= 150 ? 'opacity-0' : 'opacity-100';


  return (
    <div className={`fixed top-0 z-40 flex flex-wrap items-center op justify-between w-full px-2 py-5 overflow-hidden bg-blue-950 ${headerOpacity} transition ease-in-out xl:py-16 lg:py-16 md:py-10 sm:py-5`}>
        <div className="container flex flex-wrap items-center justify-between px-4 mx-auto">
            <div className="relative flex justify-between w-full lg:w-auto lg:static lg:block lg:justify-start">
                <div className='mt-12 text-white animate-fade'> 
                    <div className='text-xl font-bold xl:text-5xl lg:text-5xl md:text-3xl sm:text-xl'>{title}</div>
                    <div className='mt-0 text-sm xl:mt-4 lg:mt-4 md:mt-2 sm:mt-0 xl:text-xl lg:text-xl md:text-md sm:text-sm'>{description}</div>
                </div>
            </div>
        </div>
    </div>

  )
}

export default Header